var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "q-form",
          { ref: "editForm1" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "조치내용 상세" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [_c("q-btn-group", { attrs: { outline: "" } })],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          disabled: true,
                          editable: _vm.imprEditable,
                          codeGroupCd: "IBM_TASK_TYPE_CD",
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "ibmTaskTypeCd",
                          label: "업무",
                        },
                        model: {
                          value: _vm.impr.ibmTaskTypeCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "ibmTaskTypeCd", $$v)
                          },
                          expression: "impr.ibmTaskTypeCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.imprEditable,
                          disabled: true,
                          label: "요청부서/이름/일자",
                          name: "request",
                        },
                        model: {
                          value: _vm.request,
                          callback: function ($$v) {
                            _vm.request = $$v
                          },
                          expression: "request",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          editable: _vm.imprEditable,
                          required: true,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.impr.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "plantCd", $$v)
                          },
                          expression: "impr.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.imprEditable,
                          label: "조치완료일",
                          name: "actionCompleteDate",
                        },
                        model: {
                          value: _vm.impr.actionCompleteDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "actionCompleteDate", $$v)
                          },
                          expression: "impr.actionCompleteDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.imprEditable,
                          required: true,
                          label: "제목",
                          name: "ibmTitle",
                        },
                        model: {
                          value: _vm.impr.ibmTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "ibmTitle", $$v)
                          },
                          expression: "impr.ibmTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.imprEditable,
                          required: true,
                          label: "조치내용",
                          name: "actionContents",
                        },
                        model: {
                          value: _vm.impr.actionContents,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "actionContents", $$v)
                          },
                          expression: "impr.actionContents",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "q-form",
          { ref: "editForm2", attrs: { disabled: !_vm.imprEditable } },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "조치 사진" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-upload", {
                        attrs: {
                          editable: _vm.imprEditable,
                          attachInfo: _vm.attachBeforeInfo,
                          label: "개선 전 사진",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-upload", {
                        attrs: {
                          editable: _vm.imprEditable,
                          attachInfo: _vm.attachAfterInfo,
                          label: "개선 후 사진",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "popup-bottom-bar" }, [
      _c(
        "div",
        { staticClass: "popup-bottom-bar-btngroup" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _c("c-btn", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imprEditable,
                    expression: "imprEditable",
                  },
                ],
                attrs: {
                  flat: true,
                  isSubmit: _vm.isSave,
                  url: _vm.saveUrl,
                  param: _vm.impr,
                  mappingType: _vm.mappingType,
                  label: "저장",
                  icon: "save",
                },
                on: {
                  beforeAction: _vm.saveImpr,
                  btnCallback: _vm.saveCallback,
                },
              }),
              _c("c-btn", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imprEditable,
                    expression: "imprEditable",
                  },
                ],
                attrs: {
                  flat: true,
                  isSubmit: _vm.isDelete,
                  url: _vm.deleteUrl,
                  param: _vm.impr,
                  mappingType: "DELETE",
                  label: "삭제",
                  icon: "remove",
                },
                on: {
                  beforeAction: _vm.removeImpr,
                  btnCallback: _vm.removeCallback,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "popup-bottom-bar-close" },
        [
          _c("q-btn", {
            attrs: { flat: "", color: "gray", icon: "arrow_back" },
            on: { click: _vm.closePopUps },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }