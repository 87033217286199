<template>
  <div class="row">
    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-step
        :items="stepItems"
        :disabled="true"
        v-model="step">
      </c-step>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm1">
        <c-card title="조치내용 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- <c-btn
                v-show="imprEditable"
                :isSubmit="isSave"
                :url="saveUrl"
                :param="impr"
                :mappingType="mappingType"
                label="저장"
                icon="save"
                @beforeAction="saveImpr"
                @btnCallback="saveCallback" />
              <c-btn 
                v-show="imprEditable" 
                :isSubmit="isDelete"
                :url="deleteUrl"
                :param="impr"
                mappingType="DELETE"
                label="삭제" 
                icon="remove"
                @beforeAction="removeImpr"
                @btnCallback="removeCallback" /> -->
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-select
                :disabled="true"
                :editable="imprEditable"
                codeGroupCd="IBM_TASK_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="ibmTaskTypeCd"
                label="업무"
                v-model="impr.ibmTaskTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="imprEditable"
                :disabled="true"
                label="요청부서/이름/일자"
                name="request"
                v-model="request">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-plant
                :editable="imprEditable"
                :required="true"
                type="edit"
                name="plantCd"
                v-model="impr.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                :editable="imprEditable"
                label="조치완료일"
                name="actionCompleteDate"
                v-model="impr.actionCompleteDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="imprEditable"
                :required="true"
                label="제목"
                name="ibmTitle"
                v-model="impr.ibmTitle">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="imprEditable"
                :required="true"
                label="조치내용"
                name="actionContents"
                v-model="impr.actionContents">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm2" :disabled="!imprEditable">
        <c-card title="조치 사진" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-upload
                :editable="imprEditable"
                :attachInfo="attachBeforeInfo"
                label="개선 전 사진">
              </c-upload>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-upload
                :editable="imprEditable"
                :attachInfo="attachAfterInfo"
                label="개선 후 사진">
              </c-upload>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            :flat="true"
            v-show="imprEditable"
            :isSubmit="isSave"
            :url="saveUrl"
            :param="impr"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveImpr"
            @btnCallback="saveCallback" />
          <c-btn 
            :flat="true"
            v-show="imprEditable" 
            :isSubmit="isDelete"
            :url="deleteUrl"
            :param="impr"
            mappingType="DELETE"
            label="삭제" 
            icon="remove"
            @beforeAction="removeImpr"
            @btnCallback="removeCallback" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-imm-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          isSearch: false,
          sopImprovementId: '',
          ibmTaskTypeCd: '',
          requestContents: '',
        }
      },
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    }
  },
  data() {
    return {
      mappingType: 'POST',
      impr: {
        sopImprovementId: '',  // 개선 일련번호
        plantCd: '',  // 사업장 코드
        ibmTaskTypeCd: '',  // 업무분류코드
        ibmTitle: '',  // 제목
        request: '',
        improveRequestDeptCd: '',  // 개선요청부서
        improveRequestDeptName: '', // 개선요청부서명
        improveRequestUserId: '',  // 개선요청자
        improveRequestUserName: '',  // 개선요청자명
        improveRequestContents: '',  // 개선요청내용
        ibmStepCd: '',  // 개선진행단계
        relationTableKey: '',  // 관련테이블 키
        relationVendor: '',  // 관련업체_기술
        actionScheduleDate: '',  // 조치예정일
        actionCompleteRequestDate: '',  // 조치완료 요청일
        reviewCompleteRequestDate: '',  // 검토완료 요청일
        actionCompleteDate: '',  // 조치완료일
        actionDeptCd: '',  // 조치부서_조치부서는 변경가능
        actionContents: '',  // 조치내용_조치부서에서
        reviewContents: '',  // 요청부서 조치결과 검토_요청부서에서
        actionCompleteCheckUserId: '',  // 조치완료 확인자_부서장 또는 공장장
        actionCompleteCheckFlag: '',  // 조치완료 확인자 확인 여부
        requestRejectReason: '', // 요청취소사유
        regUserId: '',  // 등록자 ID
        regDt: '',
        chgUserId: '',  // 수정자 ID
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_AFTER',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      isDelete: false, // 삭제 flag
    };
  },
  computed: {
    request() {
      return this.impr.improveRequestDeptName + ' / ' + this.impr.improveRequestUserName + ' / '  + this.impr.regDt
    },
    disabled() {
      return this.popupParam.isSearch;
    },
    imprEditable() {
      return this.editable && !this.disabled
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.get.url;
      this.saveUrl = transactionConfig.sop.ibm.improve.update.url
      this.deleteUrl = transactionConfig.sop.ibm.improve.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopImprovementId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopImprovementId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.impr, _result.data);

          this.attachBeforeInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)
          this.attachAfterInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)

          this.returnData.col1 = this.impr;
        },);
        this.saveUrl = transactionConfig.sop.ibm.improve.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.ibm.improve.insert.url
        this.mappingType = 'POST';
        
        this.impr.plantCd = this.$store.getters.user.plantCd;
        this.impr.actionDeptCd = this.$store.getters.user.deptCd;
        this.impr.actionCompleteDate = this.$comm.getToday();
        this.impr.ibmClassCd = 'IC00000005';
        this.impr.ibmStepCd = 'IS00000020';
        this.impr.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
        this.impr.improveRequestDeptCd = this.$store.getters.user.deptCd;
        this.impr.improveRequestDeptName = this.$store.getters.user.deptName;
        this.impr.improveRequestUserId = this.$store.getters.user.userId;
        this.impr.improveRequestUserName = this.$store.getters.user.userName;
        this.impr.regDt = this.$comm.getToday();
        this.impr.regUserId = this.$store.getters.user.userId;
        this.impr.relationTableKey = this.popupParam.relationTableKey;
        this.impr.ibmTitle = this.popupParam.requestContents + '_' + this.$comm.getToday();
      }
    },
    /**
     * 요청&접수 상세 저장
     */
    saveImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 삭제
     */
    removeImpr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.deleteUrl = this.$format(transactionConfig.sop.ibm.improve.delete.url, this.popupParam.sopImprovementId);

          this.isDelete = !this.isDelete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup', this.returnData, 'delete');
    },
    closePopUps() {
      this.$emit('closePopup', this.returnData)
    },
  }
};
</script>